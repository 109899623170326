import React, { useState, useEffect } from "react"
import queryString from 'query-string'

import {
  theme
} from '../constants'

import AutoHeightSection from '../components/AutoHeightSection/AutoHeightSection.jsx'
import Container from '../components/Container/Container.jsx'
import ContentContainer from '../components/ContentContainer/ContentContainer.jsx'
import RegisterForm from '../components/RegisterForm/RegisterForm.jsx'
import Navbar from '../components/Navbar/Navbar.jsx'
import Layout from '../components/Layout'

export default function Regulamin({ path }) {
  const [isDev, setIsDev] = useState(false)

  useEffect(() => {
    const qs = queryString.parse(window.location.search)
    if ('_allowDev' in qs) {
      if (qs._allowDev === 'yes') {
        setIsDev(true)
      }
    }
  }, [])

  return (
    <Layout currentPath={path}>
      <Navbar bg={theme.colorBeige} />
      <AutoHeightSection bg={theme.colorBeige}>
        <Container narrow={true} pt="12" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
          <ContentContainer ptMobile="4">
            <h1>Rejestracja</h1>
            <p>
              Użyj poniższego formularza by założyć swoje konto na Korkach z Front-endu.
              <br />
              Po założeniu konta będziesz mógł zarządzać swoją subskrypcją i płatnościami.
              <br />
              Cena korków to <strong>349 zł</strong> za miesiąc uczestnictwa.
              <br />
              <small>(konto nie zobowiązuje do płatności i uczestnictwa w programie)</small>
            </p>
          </ContentContainer>
        </Container>
      </AutoHeightSection>
      <RegisterForm />
    </Layout>
  )
}
